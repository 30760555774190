import React,{useState} from 'react'
import { Row, Col, Container} from 'react-bootstrap'
import {BsFacebook, BsInstagram} from 'react-icons/bs'
import './ContactInfo.css'

function ContactInfo() {
    const iconSize = '2em'
    const titles = ['Τηλέφωνο', 'Ε-mail', 'Διεύθυνση', 'Ωράριο Λειτουργίας'];
    const content = ['25310-21753 6972726337','kostoglo@otenet.gr','1ο χιλ. Κομοτηνής-Κοσμίου', '6.30΄- 16:10΄ Δευτέρα-Παρασκευή'];
    
    const [hoveredFirst, setHoveredFirst] = useState(false);
    const [hoveredSecond, setHoveredSecond] = useState(false);
    const code = [];
    for(let i =0;i<titles.length;i+=1){
        code.push(
            <Col xs={12} sm={6} key={i}>
                <div  className="single-contact-wrapper">
                    <h2>{titles[i]}</h2>
                    <h3>{content[i]}</h3>
                </div>
            </Col>
        )
    }
    return (
        <Container>
            <h1>Επικοινωνία</h1>
            <br/><br/>
            <Row className='justify-content-center contacts-wrapper'>
                {
                    code
                }
                <Col xs={12} sm={6} className="single-contact-wrapper">
                    <h2>Social Media</h2>
                    <div className="d-flex justify-content-center">
                        <a href="https://www.facebook.com/lilipoupoli.edu/?ref=bookmarks"  aria-label="facebook link" onMouseEnter={()=>setHoveredFirst(true)} onMouseLeave={()=>setHoveredFirst(false)}>
                            <BsFacebook size={iconSize} color={hoveredFirst?'#F3D250':'#22577E'}/>
                        </a>
                        <a href="https://www.instagram.com/_lilipoupoli_/?hl=el" aria-label="instagram link" onMouseEnter={()=>setHoveredSecond(true)} onMouseLeave={()=>setHoveredSecond(false)}>
                            <BsInstagram size={iconSize} color={hoveredSecond?'#F3D250':'#22577E'}/>
                        </a>
                    </div>
                </Col>
            </Row>
        </Container>
    )
}

export default ContactInfo
