import React,{useState} from 'react'
import {Row, Tab} from 'react-bootstrap'

const TabContentButtonless = ({
    eventKey,
    info
}) => {
  return (
    <Tab.Pane eventKey={eventKey}>
        <div>
            {info}
        </div>
    </Tab.Pane>
  )
}

export default TabContentButtonless