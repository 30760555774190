import React from 'react'
import { BsFacebook, BsInstagram } from 'react-icons/bs'
import './Footer.css'

const Footer = () => {
  const iconSize = '1.3em'
  return (
    <footer className="footer mt-auto">
      <div className="text-center p-3" style={{ backgroundColor: "#22577E" }}>
        <div className='row'>
          <div className='col'>
            <ol>
              <li>Παιδικός σταθμός:</li>
              <li>
                <a className='program' href={`${process.env.PUBLIC_URL}/${encodeURI("ΕΣΩΤΕΡΙΚΟΣ_ΚΑΝΟΝΙΣΜΟΣ_2021-2022.docx")}`} download>ΕΣΩΤΕΡΙΚΟΣ ΚΑΝΟΝΙΣΜΟΣ 2021-2022</a>
              </li>
            </ol>
          </div>
          <div className='col'>
            <ol>
              <li>Νηπιαγωγείο:</li>
              <li>
              <a className='program' href={`${process.env.PUBLIC_URL}/${encodeURI("objective.pdf")}`} download>ΣΧΕΔΙΟ ΔΡΑΣΗΣ</a>
              </li>
            </ol>
          </div>
          <div className='col'>
            <ol>
              <li>Επικοινωνία:</li>
              <li>25310-21753, 6972726337</li>
              <li>kostoglo@otenet.gr</li>
              <li>
                <a href="https://www.facebook.com/lilipoupoli.edu/?ref=bookmarks" aria-label="facebook link">
                  <BsFacebook size={iconSize} color={'white'} />
                </a>
                <a href="https://www.instagram.com/_lilipoupoli_/?hl=el" aria-label="instagram link">
                  <BsInstagram size={iconSize} color={'white'} />
                </a></li>
            </ol>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer