const GeneralHistory = 'Ο βρεφονηπιακός σταθμός «Λιλιπούπολη» ξεκίνησε τη δραστηριότητά του το 1988 στην Κομοτηνή σε ενοικιαζόμενο κτήριο επί της οδού Υψηλάντου. Το 1994 εγκαταστάθηκε σε \
                    ιδιόκτητο νεόδμητο κτήριο στο 1ο χιλ. Κομοτηνής- Κοσμίου και από το 2018 λειτουργεί και τμήμα Ιδιωτικού Νηπιαγωγείου. Οι εγκαταστάσεις του αποτελούν πρότυπο για \
                    την πόλη, πληρώντας όλες τις σύγχρονες προδιαγραφές, με ηλιόλουστες αίθουσες, μεγάλη περιφραγμένη αυλή με πιστοποιημένα όργανα παιδικής χαράς και δάπεδο ασφαλείας. \
                    Σε ένα καταπράσινο περιποιημένο χώρο, με άνετο parking για τους γονείς. Λειτουργούν σ’ αυτόν τμήματα βρεφών, προνηπίων και νηπίων, ηλικίας από 6 μηνών έως το \
                    έτος εγγραφής τους στο Δημοτικό Σχολείο.'

const GeneralGoals = 'Σκοπός της “Λιλιπούπολης” είναι να προσφέρει στις μητέρες ένα περιβάλλον όπου τα παιδιά τους θα νοιώθουν οικεία και \
                        ασφαλή, πλούσιο σε ερεθίσματα και δραστηριότητες που θα αποτελούν εφαλτήρια έξαψης της φαντασίας και της δημιουργικότητάς τους. Βασική προτεραιότητα είναι να \
                        είναι τα παιδιά χαρούμενα και ευτυχισμένα ώστε να έχουν θετική αντίληψη για το σχολείο, να είναι ισορροπημένα ψυχικά και να αποκτήσουν εμπιστοσύνη στον εαυτό τους \
                        και τις ικανότητές τους. '

const classOne = 'This is a sample text. This is a sample text. This is a sample text. This is a sample text. This is a sample text. This is a sample text. This is a sample text. \
                  This is a sample text. This is a sample text. This is a sample text. This is a sample text. This is a sample text. This is a sample text. '

const classTwo = 'This is a sample text. This is a sample text. This is a sample text. This is a sample text. This is a sample text. This is a sample text. This is a sample text. \
                  This is a sample text. This is a sample text. This is a sample text. This is a sample text. This is a sample text. This is a sample text. '

const classThree = 'This is a sample text. This is a sample text. This is a sample text. This is a sample text. This is a sample text. This is a sample text. This is a sample text. \
                  This is a sample text. This is a sample text. This is a sample text. This is a sample text. This is a sample text. This is a sample text. '

const benefits = 'Η μεταφορά γίνεται με σχολικό λεωφορείο από οδηγό που κατέχει επαγγελματικό δίπλωμα και είναι κάτοχος ΠΕΙ. Τα δρομολόγια δεν είναι μεγάλης διάρκειας και τα παιδιά συνοδεύονται πάντα από παιδαγωγό.'

const benefits2 = 'Παρέχεται δεκατιανό και μεσημεριανό φαγητό. Οι γονείς παραλαμβάνουν το πρόγραμμα φαγητού κάθε αρχές του μήνα. Χρησιμοποιούνται μόνο αγνά και φρέσκα υλικά χωρίς συντηρητικά, με σπιτικές συνταγές. Το πρόγραμμα του φαγητού είναι εναρμονισμένο με τις οδηγίες του Υπουργείου Υγείας.'

const benefits3 = 'Οργανώνονται σεμινάρια για το προσωπικό του Βρεφονηπιακού Σταθμού και του Νηπιαγωγείου, ημερίδες και ομιλίες προς τους γονείς.'
export{
    GeneralHistory,
    GeneralGoals,
    classOne,
    classTwo,
    classThree,
    benefits,
    benefits2,
    benefits3
}