import React from 'react'
import NewsComp from '../NewsComp/NewsComp'
import news1 from '../../images/news1.webp'
import news2 from '../../images/news2.webp'

const NewsWrapper = () => {
  return (
    <>
      <h1>Ανακοινώσεις</h1>
      <div className="news-wrapper">
        <NewsComp
          dateName="Δευ"
          dateNumber={3}
          bodyImage={news2}
        />
        <hr />
        <NewsComp
          dateName="Παρ"
          dateNumber={10}
          bodyImage={news1}
        />
        <hr />
      </div>
    </>
  )
}

export default NewsWrapper