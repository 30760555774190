import React,{useEffect, useState} from 'react'
import { Container, Button, Fade } from 'react-bootstrap'
import './LandingImageWithRouting.css'
import { useNavigate } from "react-router-dom";
import bannerImage from '../../images/banner.png'

const LandingImageWithRouting = () => {
    const [height, setHeight] = useState(window.innerHeight);
    const [width, setWidth] = useState(window.innerWidth);
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);

    
    useEffect(()=>{
        setTimeout(()=>setOpen(true),300);
        
    },[]);

    useEffect(()=>{
        window.addEventListener('resize', ()=>{setHeight(window.innerHeight); setWidth(window.innerWidth)});
    })
    const firstDest = ()=>{
        let element = document.getElementById("kindergarder");
        element.scrollIntoView(true);
        //navigate('/pre-school')
    }

    const secondDest = ()=>{
        let element = document.getElementById("pre-school");
        element.scrollIntoView(true);
        //navigate('/kindergarden')
    }

    return (
        <Container className='landing-image-wrapper' fluid style={{minHeight: height>600? height/width>1.25?width*1.25:height:600}}>
            <a className='landing-banner-wrapper' href='https://www.espa.gr/el/Pages/Default.aspx'>
                <img className='landing-banner-image' src={bannerImage} alt="πλαίσιο ΕΔΕΤ εθνικής οικονιμίας"/>
            </a>
            {/* <img alt='main building' className='image-routing' src={logo} style={{height: height>600? height/width>1.25?width*1.25:height:600}}/> */}
            <Fade in={open} timeout={1000}>
                <div>
                    <h1 className="landing-title">Η Λιλιπούπολη</h1>
                    <h2 className="landing-subtitle">Βρεφονηπιακός Σταθμός<br/> και <br/> Ιδιωτικό Νηπιαγωγείο</h2>
                    <div className='d-flex justify-content-evenly buttons-wrapper'>
                        <Button variant="outline-light" onClick={()=>firstDest()} className='first-landing-button'>Παιδικός Σταθμός</Button>
                        <Button variant="outline-light" onClick={()=>secondDest()} className='second-landing-button'>Νηπιαγωγείο</Button>
                    </div>
                </div>
            </Fade>
        </Container>
    )
}

export default LandingImageWithRouting