import React,{useState, useEffect} from 'react'
import {Row, Col, Tab, Nav,Container} from 'react-bootstrap'
import './GeneralInfo.css'

const GeneralInfo = ({
    id,
    tabs,
    eventKeys,
    tabContents,
    title,
    defaultKey
}) => {
    const [windowSize, setWindowSize] = useState({
        width: undefined,
        height: undefined,
      });

    useEffect(() => {
        // Handler to call on window resize
        function handleResize() {
          // Set window width/height to state
          setWindowSize({
            width: window.innerWidth,
            height: window.innerHeight,
          });
        }
        // Add event listener
        window.addEventListener("resize", handleResize);
        // Call handler right away so state gets updated with initial window size
        handleResize();
        // Remove event listener on cleanup
        return () => window.removeEventListener("resize", handleResize);
      }, []);
  return (
      <Container fluid id={id}>
        <h1>{title}</h1>
        <Tab.Container id="left-tabs" defaultActiveKey={defaultKey}>
            <Row className="general-info-wrapper">
                <Col className="tab-col" sm={3}>
                    <Nav variant="pills" className={`flex-${windowSize.width<580?`row`:`column`}  justify-content-center`}>
                        {tabs.map((tab, index)=>(
                            <Nav.Item key={index}>
                                <Nav.Link key={index+100} eventKey={eventKeys[index]} className={`${windowSize.width<580?`info-tab-row`:`info-tab`}`}>{tab}</Nav.Link>
                            </Nav.Item>
                        ))}
                    </Nav>
                </Col>
                <Col className="info-tab-content" sm={9}>
                <Tab.Content>
                    {tabContents.map((content, index)=>{
                        return (
                            <React.Fragment key={index}>
                                {content}
                            </React.Fragment>
                        )
                    })}
                </Tab.Content>
                </Col>
            </Row>
        </Tab.Container>
    </Container>
  )
}

export default GeneralInfo