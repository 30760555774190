import React from 'react'
import './NewsComp.css'

const NewsComp = ({dateName, dateNumber, bodyImage}) => {
  return (
    <div className='row mb-3'>
        <div className='col-md-1'>
            <h2 style={{textAlign:'center'}}>{dateName}</h2>
            <h2 style={{textAlign:'center', fontWeight:'bold'}}>{dateNumber}</h2>
        </div>
        <div className='news-image-body-wrapper'>
            <img className='news-image-body' src={bodyImage} alt="First news image"/>
        </div>
    </div>
  )
}

export default NewsComp