import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Routing from './routing/Routing';
import Footer from './components/Footer/Footer';
import { useLocation } from 'react-router-dom';

function App() {
  const location = useLocation();
  
  return (
    <>
      <Routing />
      <Footer />
    </>
  );
}

export default App;
